/**
 * Created by jerry on 2020/03/5.
 * 项目分类api
 */
import * as API from '@/api/index'

export default {
    // 项目分类列表
    getProjectCategory: params => {
        return API.POST('api/projectCategory/all', params)
    },
    //新增项目分类
    createProjectCategory: params => {
        return API.POST('api/projectCategory/create', params)
    },
    //编辑项目分类
    updateProjectCategory:params => {
        return API.POST('api/projectCategory/update', params)
    },
    // 一级项目分类列表
    getProjectCategoryTop: params => {
        return API.POST('api/projectCategory/top', params)
    },
    //项目分类顺序调整
    moveProjectCategory:params => {
        return API.POST('api/projectCategory/move', params)
    },

}