<template>
  <div
    class="content_body"
    v-loading="loading"
    style="height: calc(100% - 30px)"
  >
    <el-tabs
      class="tab_pane"
      tab-position="left"
      v-model="cardIndex"
      @tab-click="handleClick"
      style="height: 100%"
    >
      <el-tab-pane
        v-for="item in cardTypeList"
        :key="item.name"
        :label="item.label"
        :name="item.name"
        style="height: 100%"
      >
        <div class="nav_header">
          <el-row>
            <el-col :span="20">
              <el-form
                :inline="true"
                size="small"
                class="demo-form-inline"
                @keyup.enter.native="handleSearch"
              >
                <el-form-item label="分类名称">
                  <el-input
                    v-model="name"
                    @clear="handleSearch"
                    clearable
                    placeholder="输入分类名称搜索"
                  ></el-input>
                </el-form-item>
                <el-form-item label="有效性">
                  <el-select
                    @change="handleSearch"
                    v-model="active"
                    @clear="handleSearch"
                    placeholder="请选择"
                    clearable
                  >
                    <el-option label="有效" :value="true"></el-option>
                    <el-option label="无效" :value="false"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="handleSearch"
                    >搜索</el-button
                  >
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="4" class="text_right">
              <el-button type="primary" size="small" @click="showAddDialog"
                >添加分类</el-button
              >
            </el-col>
          </el-row>
        </div>
        <div class="martp_10">
          <el-table
            size="small"
            :data="tableData"
            row-key="ID"
            :tree-props="{ children: 'Child' }"
          >
            <el-table-column prop="Name" label="分类名称"></el-table-column>
            <el-table-column label="移动" min-width="180px">
              <template slot-scope="scope">
                <el-button
                  size="small"
                  type="primary"
                  circle
                  icon="el-icon-upload2"
                  :disabled="scope.$index == 0"
                  @click="upOneClick(scope.row, scope.$index)"
                ></el-button>
                <el-button
                  size="small"
                  type="primary"
                  circle
                  :disabled="scope.$index == 0"
                  icon="el-icon-top"
                  @click="upClick(scope.row, scope.$index)"
                ></el-button>
                <el-button
                  size="small"
                  type="primary"
                  circle
                  :disabled="scope.$index == tableDataLength-1"
                  icon="el-icon-bottom"
                  @click="downClick(scope.row, scope.$index)"
                ></el-button>
                <!-- scope.$index -->
                <el-button
                  size="small"
                  type="primary"
                  circle
                  :disabled="scope.$index == tableDataLength -1"
                  icon="el-icon-download"
                  @click="downOneClick(scope.row, scope.$index)"
                ></el-button>
              </template>
            </el-table-column>
            <el-table-column
              prop="Active"
              label="有效性"
              :formatter="formatStatus"
            ></el-table-column>
            <el-table-column label="操作" width="180px">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="small"
                  @click="showEditDialog(2, scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  @click="showEditDialog(1, scope.row)"
                  v-if="scope.row.ParentID == 0"
                  >添加子分类</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!--分类弹窗-->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          size="small"
        >
          <el-form-item label="分类名称" prop="Name">
            <el-input v-model="ruleForm.Name" clearable></el-input>
          </el-form-item>
          <el-form-item label="是否有效" v-if="isadd == 2">
            <el-radio-group v-model="ruleForm.Active">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="addSubmit"
          :loading="modalLoading"
          >保 存</el-button
        >
      </div>
    </el-dialog>

    <!--子分类弹窗-->
    <el-dialog :title="dialogTitle" :visible.sync="dialogSubClass" width="30%">
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          size="small"
          class="demo-ruleForm"
        >
          <el-form-item
            label="上级分类"
            prop="ParentID"
            v-show="ruleForm.ParentID != 0"
          >
            <el-select
              v-model="ruleForm.ParentID"
              placeholder="请选择分类"
              disabled
            >
              <el-option
                v-for="(category, index) in projectCategory"
                :key="index"
                :label="category.Name"
                :value="category.ID"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分类名称" prop="Name">
            <el-input clearable v-model="ruleForm.Name"></el-input>
          </el-form-item>
          <el-form-item label="是否有效" v-show="isadd == 2">
            <el-radio-group v-model="ruleForm.Active">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogSubClass = false"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="addSubmit"
          :loading="modalLoading"
          >保 存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import APIProject from "@/api/iBeauty/Basic/projectCategory";
import APIGeneral from "@/api/iBeauty/Basic/generalCardCategory";
import APIPackage from "@/api/iBeauty/Basic/packageCardCategory";
import APISaving from "@/api/iBeauty/Basic/savingCardCategory";
import APITime from "@/api/iBeauty/Basic/timeCardCategory";

export default {
  name: "GoodsCategory",
  data() {
    return {
      loading: false,
      modalLoading: false,
      dialogVisible: false,
      dialogSubClass: false,
      name: "",
      active: true,
      dialogTitle: "新增分类",
      isadd: 1,
      moveID: "",
      moveIndex: 1,
      projectCategory: [],
      tableData: [],
      cardIndex: "1",
      cardTypeList: [
        { label: "项目分类", name: "1" },
        { label: "通用次卡分类", name: "2" },
        { label: "时效卡分类", name: "3" },
        { label: "储值卡分类", name: "4" },
        { label: "套餐卡分类", name: "5" },
      ],
      ruleForm: {
        Name: "",
        ParentID: 0,
        Active: "",
      },
      rules: {
        Name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        Active: [
          { required: true, message: "请选择有效性", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    tableDataLength() {
      var tableData = this.tableData;
      var length = 0;
      if (tableData && tableData.length > 0) {
        tableData.forEach((item, index) => {
          if (item.Child && index != tableData.length - 1) {
            length += item.Child.length;
          }
          length += 1;
        });
      }
      return length;
    },
  },
  methods: {
    // //状态显示转换
    formatStatus: function (row) {
      return row.Active ? "有效" : "无效";
    },
    // 一级项目分类
    projectCategoryTop: function () {
      let that = this;
      that.loading = true;

      APIProject.getProjectCategoryTop()
        .then((res) => {
          if (res.StateCode == 200) {
            that.projectCategory = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 选择卡项
    handleClick: function () {
      var that = this;
      that.handleSearch();
    },
    // 数据显示
    handleSearch: function () {
      let that = this;
      switch (that.cardIndex) {
        case "1":
          that.projectCategoryData();
          break;
        case "2":
          that.generalCategoryData();
          break;
        case "3":
          that.timeCardCategory();
          break;
        case "4":
          that.savingCardCategory();
          break;
        case "5":
          that.packageCardCategory();
          break;
      }
    },
    // 项目分类
    projectCategoryData: function () {
      let that = this;
      that.loading = true;
      var params = {
        Name: that.name,
        Active: that.active,
      };
      APIProject.getProjectCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 通用次卡分类
    generalCategoryData: function () {
      let that = this;
      that.loading = true;
      var params = {
        Name: that.name,
        Active: that.active,
      };
      APIGeneral.getGeneralCardCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 时效卡分类
    timeCardCategory: function () {
      let that = this;
      that.loading = true;
      var params = {
        Name: that.name,
        Active: that.active,
      };
      APITime.getTimeCardCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 储值卡
    savingCardCategory: function () {
      let that = this;
      that.loading = true;
      var params = {
        Name: that.name,
        Active: that.active,
      };
      APISaving.getSavingCardCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 套餐卡
    packageCardCategory: function () {
      let that = this;
      that.loading = true;
      var params = {
        Name: that.name,
        Active: that.active,
      };
      APIPackage.getPackageCardCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 新增
    showAddDialog: function () {
      var that = this;
      that.isadd = 1;
      that.dialogTitle = "新增分类";
      if (that.cardIndex == "1") {
        that.ruleForm = {
          Name: "",
          ParentID: 0,
          Active: "",
        };
        that.dialogSubClass = true;
      } else {
        that.ruleForm = {
          Name: "",
          Active: "",
        };
        that.dialogVisible = true;
      }
    },
    // 编辑
    showEditDialog: function (index, row) {
      var that = this;
      that.isadd = index;
      if (that.cardIndex == "1") {
        // 添加子分类
        if (index == 1) {
          that.ruleForm = {
            Name: "",
            ParentID: row.ID,
            Active: "",
          };
          that.dialogTitle = "新增子分类";
        } else {
          that.dialogTitle = "编辑分类";
          that.ruleForm = Object.assign({}, row);
        }
        that.dialogSubClass = true;
      } else {
        // 编辑分类
        that.dialogTitle = "编辑分类";
        that.ruleForm = Object.assign({}, row);
        that.dialogVisible = true;
      }
    },
    // 提交
    addSubmit: function () {
      let that = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let para = Object.assign({}, that.ruleForm);
          if (that.isadd == 1) {
            switch (that.cardIndex) {
              case "1":
                that.addProjectCategory(para);
                break;
              case "2":
                that.addGeneralCategory(para);
                break;
              case "3":
                that.addTimeCategory(para);
                break;
              case "4":
                that.addSavingCategory(para);
                break;
              case "5":
                that.addPackageCategory(para);
                break;
            }
          } else {
            switch (that.cardIndex) {
              case "1":
                that.editProjectCategory(para);
                break;
              case "2":
                that.editGeneralCategory(para);
                break;
              case "3":
                that.editTimeCategory(para);
                break;
              case "4":
                that.editSavingCategory(para);
                break;
              case "5":
                that.editPackageCategory(para);
                break;
            }
          }
        } else {
          return;
        }
      });
    },
    // 项目分类新增
    addProjectCategory: function (para) {
      var that = this;
      that.modalLoading = true;
      APIProject.createProjectCategory(para)
        .then(function (res) {
          if (res.StateCode === 200) {
            that.$message.success({
              message: "新增成功",
              duration: 2000,
            });
            that.handleSearch();

            that.$refs["ruleForm"].resetFields();
            that.projectCategoryTop();
          } else {
            that.$message.error({
              showClose: true,
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.dialogSubClass = false;
          that.modalLoading = false;
        });
    },
    // 项目分类编辑
    editProjectCategory: function (para) {
      var that = this;
      that.modalLoading = true;
      var params = {
        ID: para.ID,
        ParentID: para.ParentID,
        Name: para.Name,
        Active: para.Active,
      };
      APIProject.updateProjectCategory(params)
        .then(function (res) {
          if (res.StateCode === 200) {
            that.$message.success({
              message: "编辑成功",
              duration: 2000,
            });
            that.$refs["ruleForm"].resetFields();
            that.handleSearch();
            that.projectCategoryTop();
          } else {
            that.$message.error({
              showClose: true,
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.dialogSubClass = false;
          that.modalLoading = false;
        });
    },
    // 通用次卡分类新增
    addGeneralCategory: function (para) {
      var that = this;
      that.modalLoading = true;
      APIGeneral.createGeneralCardCategory(para)
        .then(function (res) {
          if (res.StateCode === 200) {
            that.$message.success({
              message: "新增成功",
              duration: 2000,
            });
            that.handleSearch();
            that.$refs["ruleForm"].resetFields();
          } else {
            that.$message.error({
              showClose: true,
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.dialogVisible = false;
          that.modalLoading = false;
        });
    },
    // 通用次卡分类编辑
    editGeneralCategory: function (para) {
      var that = this;
      that.modalLoading = true;
      var params = {
        ID: para.ID,
        Name: para.Name,
        Active: para.Active,
      };
      APIGeneral.updateGeneralCardCategory(params)
        .then(function (res) {
          if (res.StateCode === 200) {
            that.$message.success({
              message: "编辑成功",
              duration: 2000,
            });
            that.handleSearch();
            that.$refs["ruleForm"].resetFields();
          } else {
            that.$message.error({
              showClose: true,
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.dialogVisible = false;
          that.modalLoading = false;
        });
    },
    // 时效卡分类新增
    addTimeCategory: function (para) {
      var that = this;
      that.modalLoading = true;
      APITime.createTimeCardCategory(para)
        .then(function (res) {
          if (res.StateCode === 200) {
            that.$message.success({
              message: "新增成功",
              duration: 2000,
            });
            that.handleSearch();
            that.$refs["ruleForm"].resetFields();
          } else {
            that.$message.error({
              showClose: true,
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.dialogVisible = false;
          that.modalLoading = false;
        });
    },
    // 时效卡分类编辑
    editTimeCategory: function (para) {
      var that = this;
      that.modalLoading = true;
      var params = {
        ID: para.ID,
        Name: para.Name,
        Active: para.Active,
      };
      APITime.updateTimeCardCategory(params)
        .then(function (res) {
          if (res.StateCode === 200) {
            that.$message.success({
              message: "编辑成功",
              duration: 2000,
            });
            that.handleSearch();
            that.$refs["ruleForm"].resetFields();
          } else {
            that.$message.error({
              showClose: true,
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.dialogVisible = false;
          that.modalLoading = false;
        });
    },
    // 储值卡分类新增
    addSavingCategory: function (para) {
      var that = this;
      that.modalLoading = true;
      APISaving.createSavingCardCategory(para)
        .then(function (res) {
          if (res.StateCode === 200) {
            that.$message.success({
              message: "新增成功",
              duration: 2000,
            });
            that.handleSearch();
            that.$refs["ruleForm"].resetFields();
          } else {
            that.$message.error({
              showClose: true,
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.dialogVisible = false;
          that.modalLoading = false;
        });
    },
    // 储值卡分类编辑
    editSavingCategory: function (para) {
      var that = this;
      that.modalLoading = true;
      var params = {
        ID: para.ID,
        Name: para.Name,
        Active: para.Active,
      };
      APISaving.updateSavingCardCategory(params)
        .then(function (res) {
          if (res.StateCode === 200) {
            that.$message.success({
              message: "编辑成功",
              duration: 2000,
            });
            that.handleSearch();
            that.$refs["ruleForm"].resetFields();
          } else {
            that.$message.error({
              showClose: true,
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.dialogVisible = false;
          that.modalLoading = false;
        });
    },
    // 套餐卡分类新增
    addPackageCategory: function (para) {
      var that = this;
      that.modalLoading = true;
      APIPackage.createPackageCardCategory(para)
        .then(function (res) {
          if (res.StateCode === 200) {
            that.$message.success({
              message: "编辑成功",
              duration: 2000,
            });
            that.handleSearch();
            that.$refs["ruleForm"].resetFields();
          } else {
            that.$message.error({
              showClose: true,
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.dialogVisible = false;
          that.modalLoading = false;
        });
    },
    // 套餐卡分类编辑
    editPackageCategory: function (para) {
      var that = this;
      that.modalLoading = true;
      var params = {
        ID: para.ID,
        Name: para.Name,
        Active: para.Active,
      };
      APIPackage.updatePackageCardCategory(params)
        .then(function (res) {
          if (res.StateCode === 200) {
            that.$message.success({
              message: "编辑成功",
              duration: 2000,
            });
            that.handleSearch();
            that.$refs["ruleForm"].resetFields();
          } else {
            that.$message.error({
              showClose: true,
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.dialogVisible = false;
          that.modalLoading = false;
        });
    },
    // 移动首部
    upOneClick: function (row) {
      var that = this;
      that.moveIndex = 1;
      that.moveID = row.ID;
      if (that.cardIndex == "1") {
        that.setRecursion(that.tableData);
      } else {
        var moveId = row.ID;
        var beforeId = "";
        that.movaClick(moveId, beforeId);
      }
    },
    // 移动尾部
    downOneClick: function (row, index) {
      var that = this;
      that.moveIndex = 2;
      that.moveID = row.ID;
      if (that.cardIndex == "1") {
        that.setRecursion(that.tableData);
      } else {
        var i = that.tableData.length;
        var moveId = row.ID;
        var beforeId = "";
        if (index < i - 1) {
          beforeId = that.tableData[i - 1].ID;
        }
        that.movaClick(moveId, beforeId);
      }
    },
    // 向上
    upClick: function (row, index) {
      var that = this;
      that.moveIndex = 3;
      that.moveID = row.ID;
      if (that.cardIndex == "1") {
        that.setRecursion(that.tableData);
      } else {
        var moveId = "",
          beforeId = "";
        moveId = row.ID;
        if (index < 2) {
          beforeId = "";
        } else {
          beforeId = that.tableData[index - 2].ID;
        }
        that.movaClick(moveId, beforeId);
      }
    },
    // 向下
    downClick: function (row, index) {
      var that = this;
      that.moveIndex = 4;
      that.moveID = row.ID;
      if (that.cardIndex == "1") {
        that.setRecursion(that.tableData);
      } else {
        var moveId = "",
          beforeId = "";
        moveId = row.ID;
        if (index + 1 != that.tableData.length) {
          beforeId = that.tableData[index + 1].ID;
        }
        that.movaClick(moveId, beforeId);
      }
    },

    // 递归
    setRecursion(data) {
      var that = this;
      var index = data.length;
      var moveId = "",
        beforeId = "",
        destParentID = 0;
      for (let i = 0; i < index; i++) {
        if (data[i].ID == that.moveID) {
          if (that.moveIndex == 1) {
            moveId = data[i].ID;
            beforeId = "";
            destParentID = data[i].ParentID;
          } else if (that.moveIndex == 2) {
            moveId = data[i].ID;
            beforeId = data[index - 1].ID;
            destParentID = data[i].ParentID;
          } else if (that.moveIndex == 3) {
            moveId = data[i].ID;
            destParentID = data[i].ParentID;
            if (i == 0 || i == 1) {
              beforeId = "";
            } else {
              beforeId = data[i - 2].ID;
            }
          } else {
            moveId = data[i].ID;
            destParentID = data[i].ParentID;
            if (i == index - 1) {
              beforeId = data[i == 0?0:i- 1].ID;
            } else {
              beforeId = data[i + 1].ID;
            }
          }
          that.moveProjectCategory(moveId, beforeId, destParentID);
          return false;
        }
        if (data[i].Child) {
          that.setRecursion(data[i].Child);
        }
      }
    },
    // 移动顺序
    movaClick: function (moveId, beforeId) {
      var that = this;
      switch (that.cardIndex) {
        case "2":
          that.moveGeneralCategory(moveId, beforeId);
          break;
        case "3":
          that.moveTimeCategory(moveId, beforeId);
          break;
        case "4":
          that.moveSavingCategory(moveId, beforeId);
          break;
        case "5":
          that.movePackageCategory(moveId, beforeId);
          break;
      }
    },
    // 项目分类移动顺序
    moveProjectCategory: function (moveId, beforeId, destParentID) {
      var that = this;
      that.loading = true;
      var params = {
        MoveID: moveId,
        BeforeID: beforeId,
        DestParentID: destParentID,
      };
      APIProject.moveProjectCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "移动成功",
              duration: 2000,
            });
            that.handleSearch();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 通用次卡分类移动顺序
    moveGeneralCategory: function (moveId, beforeId) {
      var that = this;
      that.loading = true;
      var params = {
        MoveID: moveId,
        BeforeID: beforeId,
      };
      APIGeneral.moveGeneralCardCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "移动成功",
              duration: 2000,
            });
            that.handleSearch();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 时效卡分类移动顺序
    moveTimeCategory: function (moveId, beforeId) {
      var that = this;
      that.loading = true;
      var params = {
        MoveID: moveId,
        BeforeID: beforeId,
      };
      APITime.moveTimeCardCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "移动成功",
              duration: 2000,
            });
            that.handleSearch();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 储值卡分类移动顺序
    moveSavingCategory: function (moveId, beforeId) {
      var that = this;
      that.loading = true;
      var params = {
        MoveID: moveId,
        BeforeID: beforeId,
      };
      APISaving.moveSavingCardCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "移动成功",
              duration: 2000,
            });
            that.handleSearch();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 套餐卡分类移动顺序
    movePackageCategory: function (moveId, beforeId) {
      var that = this;
      that.loading = true;
      var params = {
        MoveID: moveId,
        BeforeID: beforeId,
      };
      APIPackage.movePackageCardCategory(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "移动成功",
              duration: 2000,
            });
            that.handleSearch();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted() {
    var that = this;
    that.projectCategoryTop();
    that.handleSearch();
  },
};
</script>

<style lang="scss">
.tab_pane {
  .el-tabs__content {
    height: 100% !important;
    .martp_10 {
      height: calc(100% - 76px);
      overflow-y: auto;
    }
  }
}
</style>